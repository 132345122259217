export const ATLASHOVER_LOGO = "/atlashover-logo.png";
export const ATLASHOVER_LOGO_TRIM = "/atlashover-trim.png";
export const AIRPLANE = "/airplane.jpg";
export const CHESS = "/chess.jpg";
export const ADEWALE = "/adewale.png";
export const SBA_LOGO = "/sbalogo.png";
export const VSOB_LOGO = "/vsoblogo.png";
export const VR_GIRL = "/vrgirl.jpg";
export const COMING_SOON = "/comingsoon.jpg";
export const PROJECT = "/project.jpg";
export const ROBOT_TOUCH = "/robottouch.jpg";
export const TELEPHONE = "/telephone.jpg";
export const PROGRAMMING = "/programming.jpg";
export const CHIP = "/chip.jpg";
export const STAFF = "/staff.jpg";
export const DATA_GIRL = "/datagirl.jpg";
export const TEAM = "/team.jpg";
export const JANITOR = "/janitor.jpg";
export const DESK = "/desk.jpg";
export const FLY_ENVELOPE = "/fly_envelope.svg";
export const GIRL_TOUCH = "/touchgirl.svg";
export const SVG_BACKGROUND = "/svgbg.svg";
export const CIRCLE_RING = "/circleRings.svg";
export const ICON_1 = "/icon1.svg";
export const ICON_2 = "/icon2.svg";
export const ICON_3 = "/icon3.svg";
export const ICON_4 = "/icon4.svg";
export const ICON_5 = "/icon5.svg";
export const ICON_6 = "/icon6.svg";
export const ICON_7 = "/icon7.svg";
export const ICON_8 = "/icon8.svg";
export const ICON_9 = "/icon9.svg";
export const ICON_10 = "/icon10.svg";
export const ICON_11 = "/icon11.svg";
export const ICON_12 = "/icon12.svg";
export const ICON_13 = "/icon13.svg";
export const ICON_14 = "/icon14.svg";
export const ICON_15 = "/icon15.svg";
export const ICON_16 = "/icon16.svg";
export const ICON_17 = "/icon17.svg";
export const ICON_18 = "/icon18.svg";
export const ICON_19 = "/icon19.svg";
export const ICON_20 = "/icon20.svg";
export const ICON_21 = "/icon21.svg";
export const ICON_22 = "/icon22.svg";
